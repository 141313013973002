'use client';
import { TailwindClasses } from '@ngg/storefront-utils';
import { MinusIcon, PlusIcon, CheckIcon } from '@ngg/icons';
import React, { useMemo } from 'react';
import Airmee from './assets/airmee.svg';
import Lanna from './assets/lanna.svg';
import GreenCheckmark from './assets/greenCheckmark.svg';
import GreenPlusmark from './assets/greenPlusmark.svg';
import Postnord from './assets/postnord.svg';
import RadioChecked from './assets/radioChecked.svg';
import RadioUnchecked from './assets/radioUnchecked.svg';
import Carrot from './assets/carrot.svg';
import Hamburger from './assets/hamburger.svg';
import Dot from './assets/dot.svg';
import RightArrow from './assets/rightArrow.svg';
import LeftArrow from './assets/leftArrow.svg';
import FirstPageArrow from './assets/firstPageArrow.svg';
import LastPageArrow from './assets/lastPageArrow.svg';
import Loading from './assets/loading.svg';
import DownArrow from './assets/downArrow.svg';
import UpArrow from './assets/upArrow.svg';
import Filter from './assets/filter.svg';
import Search from './assets/search.svg';
import ShoppingBag from './assets/shoppingBag.svg';
import RightArrowWithTail from './assets/rightArrowWithTail.svg';
import Facebook from './assets/socials/facebook.svg';
import Instagram from './assets/socials/instagram.svg';
import Chevron from './assets/chevron.svg';
import Sweden from './assets/flags/sweden.svg';
import Norway from './assets/flags/norway.svg';
import Payment from './assets/payment.svg';
import Cross from './assets/cross.svg';

const IconElements = {
  airmee: Airmee,
  carrot: Carrot,
  chevron: Chevron,
  cross: Cross,
  dot: Dot,
  downArrow: DownArrow,
  filter: Filter,
  firstPageArrow: FirstPageArrow,
  greenCheckmark: GreenCheckmark,
  greenPlusmark: GreenPlusmark,
  hamburger: Hamburger,
  lanna: Lanna,
  lastPageArrow: LastPageArrow,
  leftArrow: LeftArrow,
  minus: MinusIcon,
  norway: Norway,
  plus: PlusIcon,
  postnord: Postnord,
  radioChecked: RadioChecked,
  radioUnchecked: RadioUnchecked,
  rightArrow: RightArrow,
  rightArrowWithTail: RightArrowWithTail,
  search: Search,
  sweden: Sweden,
  shoppingBag: ShoppingBag,
  loading: Loading,
  checkIcon: CheckIcon,
  upArrow: UpArrow,
  facebook: Facebook,
  instagram: Instagram,
  payment: Payment,
} as const;

export type IconElements = keyof typeof IconElements;

export type IconSizes =
  | 4
  | 6
  | 8
  | 12
  | 16
  | 18
  | 20
  | 24
  | 32
  | 48
  | 64
  | 96
  | 128;

type Props = {
  name: IconElements;
  color?: string;
  className?: TailwindClasses;
  size?: IconSizes;
} & React.SVGProps<SVGSVGElement>;

export default function Icon({
  name,
  color,
  className,
  size = 16,
  ...rest
}: Props) {
  const Icon = useMemo(() => IconElements[name], [name]);

  return (
    <Icon
      color={color}
      className={className}
      {...rest}
      width={size}
      height={size}
    />
  );
}
